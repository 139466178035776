import React, { useContext } from "react";
import { AiOutlineCloudDownload, AiOutlineCloudUpload } from "react-icons/ai";
import { IoAddOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { Theme } from "../Theme";
import QRCode from "qrcode";
import { useUploadModalToggled } from "./useUploadModalToggled";
import { useTableModalToggled } from "./useTableModalToggled";
import { useEventNameModalToggled } from "./useEventNameModalToggled";
import { EventContext } from "../Context/EventContext";
import { ModalContext } from "../Context/ModalContext";
import { AuthContext } from "../Context/AuthContext";

export interface IProfileIcon {
  icon: any;
  onClick: () => void;
  label: string | JSX.Element;
  hide?: boolean;
  ref: any;
}

const profileIconStyle = { color: Theme.colors.white, width: 20, height: 20 };

export const useProfilePannelIcons = () => {
  const { onTableModalToggled } = useTableModalToggled();
  const { onUploadModalToggled } = useUploadModalToggled();
  const location = useLocation();
  const { onEventNameModalToggled } = useEventNameModalToggled();
  const { state: eventState } = useContext(EventContext);
  const { onUploadImageModalOpened } = useContext(ModalContext);
  const showEditEventName = !eventState?.event?.name;
  const {
    state: { user },
  } = useContext(AuthContext);
  const hideEditImageButton = !user?.hasPaid;
  const eventId = user?.uid!;
  const profileIcons: { [key: string]: IProfileIcon[] } = {
    "/organise": [
      {
        icon: () => <AiOutlineCloudUpload style={profileIconStyle} />,
        onClick: onUploadModalToggled,
        ref: React.createRef(),
        label: "Upload Guests",
      },
      // {
      //   icon: () => <IoPersonAddOutline style={profileIconStyle} />,
      //   onClick: onGuestModalToggled,
      //   ref: React.createRef(),
      //   label: "Add Guests",
      // },
      {
        icon: () => <IoAddOutline style={profileIconStyle} />,
        onClick: onTableModalToggled,
        ref: React.createRef(),
        label: "Add Tables",
      },
    ],
    "/event-details": [
      {
        icon: () => <AiOutlineCloudDownload style={profileIconStyle} />,
        onClick: async () => {
          const dataUrl = await QRCode.toDataURL(`${process.env.REACT_APP_DOMAIN}/seating-plan?id=${eventId}`, {
            margin: 1,
            color: {
              dark: "#000000",
              light: "#ffffff00", // Transparent background
            },
          });
          var link: HTMLAnchorElement = document.createElement("a");
          link.download = "seating-plan-qrcode.png";
          link.href = dataUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        ref: React.createRef(),
        label: "QR Code",
      },
      {
        icon: () => <AiOutlineCloudDownload style={profileIconStyle} />,
        hide: !showEditEventName || eventState.isFetchingEvent,
        onClick: () => {
          onEventNameModalToggled();
        },
        ref: React.createRef(),
        label: (
          <>
            Edit event <br /> name
          </>
        ),
      },
      {
        icon: () => <AiOutlineCloudUpload style={profileIconStyle} />,
        onClick: onUploadImageModalOpened,
        hide: hideEditImageButton,
        ref: React.createRef(),
        label: (
          <>
            Edit Custom
            <br />
            Background
          </>
        ),
      },
    ],
  };

  return { profileIcons: profileIcons[location.pathname] };
};
