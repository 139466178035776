import React from "react";
import { IAlphabeticalList } from "../../Selectors";
import { StyledEventHeading, StyledOverlay, StyledSeatingPlanImageBackgound, StyledSeatingPlanInnerContainer } from "../../Styles";
import { Theme } from "../../Theme";
import { AlphabeticalLetter } from "./AlphabeticalLetter";
interface IProps {
  alphabeticalList: IAlphabeticalList[];
  autoFillSpace: string;
  eventInfo: {
    eventName: string;
    eventImageUrl?: string;
    fontFamilyGuest?: string;
    fontFamilyTableNumber?: string;
    fontFamilyLetterHeading?: string;
    fontFamilyTitle?: string;
  };
  textStyle?: string;
}
const borderStyle = {
  border: "none",
  borderRadius: "15px",
};

const formatTitle = (title: string) => {
  return (
    <>
      {title
        .split(/(.+?[-/])/g)
        .filter(Boolean)
        .map((part, index) => (
          <React.Fragment key={index}>
            {part.replace(/\s+[-/]/, "")}
            {part.match(/[-/]$/) && <br />}
          </React.Fragment>
        ))}
    </>
  );
};

export const AlphabeticalList = ({
  alphabeticalList,
  autoFillSpace,
  textStyle,
  eventInfo: { eventName, eventImageUrl, fontFamilyGuest, fontFamilyTableNumber, fontFamilyLetterHeading, fontFamilyTitle },
}: IProps) => {
  return (
    <StyledSeatingPlanImageBackgound imageUrl={eventImageUrl}>
      <StyledOverlay backgroundColor="rgba(255,255,255,0.7)" overflow="scroll">
        <StyledSeatingPlanInnerContainer autoFillSpace={autoFillSpace}>
          <StyledEventHeading fontFamily={fontFamilyTitle}>{formatTitle(eventName)}</StyledEventHeading>
          {alphabeticalList.map((letterAndList) => {
            return (
              <AlphabeticalLetter
                letterAndList={letterAndList}
                key={letterAndList.letter}
                textStyle={textStyle}
                fontColor={Theme.colors.black}
                fontFamilyTableNumber={fontFamilyTableNumber}
                fontFamilyGuest={fontFamilyGuest}
                fontFamilyLetterHeading={fontFamilyLetterHeading}
                fontSize="38px"
                borderStyle={borderStyle}
                backgroundColor={"transparent"}
                hasLinkId={true}
                nameWidth="190px"
              />
            );
          })}
        </StyledSeatingPlanInnerContainer>
      </StyledOverlay>
    </StyledSeatingPlanImageBackgound>
  );
};
