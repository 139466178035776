import { useCallback, useContext } from "react";
import { TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";
import { GuestFormat } from "../Context/tableReducer";

export const useUploadGuests = (currentStep: number) => {
  const { uploadGuests } = useContext(TableContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const eventId = user?.uid!;
  return {
    uploadGuests: useCallback(
      (payload: { data: any; fields: any }, format: GuestFormat | undefined) => {
        uploadGuests(eventId, payload, format);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentStep]
    ),
  };
};
