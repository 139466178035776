import { ChangeEvent, useCallback, useContext, useState } from "react";
import { AddGuestsFormContainer, AddGuestsFormButtonContainer, StyledButtonBottomFlex } from "../../Styles";
import { TextStyles, Theme } from "../../Theme";
import { ChooseFormat } from "./ChooseFormat";
import { CompleteUpload } from "./CompleteUpload";
import { FormProgress } from "./FormProgress";
import { UploadTemplate } from "./UploadTemplate";
import { Button } from "..";
import { GuestFormat } from "../../Context/tableReducer";
import { TableContext, csvHasCorrectColumns } from "../../Context/TableContext";
import { ModalContext } from "../../Context/ModalContext";
import { useUploadGuests } from "../../CustomHooks/useUploadGuests";
import { ParseResult, parse } from "papaparse";
import { utils, read } from "xlsx";
import { getFormatInWords } from "../../Utils";
import { logger } from "../../Logger";

enum FormSteps {
  ChooseFormat,
  UploadTemplate,
  CompleteUpload,
}

const getCurrentStep = (step: number) => {
  return Object.keys(FormSteps).indexOf(step + "");
};

const getNextStep = (step: number): number => {
  const currentStep = getCurrentStep(step);
  if (currentStep === Object.keys(FormSteps).length / 2 - 1) {
    return currentStep;
  }
  return currentStep + 1;
};

const getPreviousStep = (step: number): number => {
  const currentStep = getCurrentStep(step);
  if (currentStep === 0) {
    return currentStep;
  }
  return currentStep - 1;
};

const buttonStyles = {
  width: "120px",
  height: "40px",
  textStyle: TextStyles.regular,
  color: Theme.colors.white,
  borderRadius: "15px",
};

export const AddGuestsForm = () => {
  const {
    state: { uploadingGuests },
    uploadGuestFailure,
    uploadGuestRequest,
  } = useContext(TableContext);
  const { onUploadModalToggled } = useContext(ModalContext);
  const [currentStep, setCurrentStep] = useState<number>(FormSteps.ChooseFormat);
  const [format, setFormat] = useState<GuestFormat | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const formatSelected = useCallback((format: GuestFormat) => {
    setFormat(format);
  }, []);
  const onFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(e.target.files?.[0] || null);
  };
  const onResetFileSelection = useCallback(() => {
    setSelectedFile(null);
  }, []);
  const { uploadGuests } = useUploadGuests(currentStep);
  const handleFileUpload = useCallback(() => {
    if (!selectedFile) {
      return;
    }
    try {
      uploadGuestRequest();
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const workbook = read(arrayBuffer, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          let data = utils.sheet_to_json(worksheet, { header: 1 });
          const lastIndex =
            data.length -
            data
              .slice()
              .reverse()
              .findIndex((arr: any) => arr.length > 0);
          data = data.slice(0, lastIndex);
          const csvData = data.map((row: any) => row.join(",")).join("\n");
          parse(csvData, {
            header: true,
            complete: (results: ParseResult<Record<string, unknown>>) => {
              if (results.data.length === 0) {
                if (csvHasCorrectColumns(results.meta.fields!, format!)) {
                  uploadGuestFailure("Your file does not appear to contain any guest data.");
                  return;
                }
                uploadGuestFailure(
                  `Your file is missing the correct column headers. Please ensure that the first row in the file contains the column headers: ${getFormatInWords(
                    format!
                  )}  as depicted in the previous step`
                );
                return;
              }
              uploadGuests({ data: results.data, fields: results.meta.fields }, format);
            },
          });
        }
      };
      reader.readAsArrayBuffer(selectedFile);
    } catch (e) {
      logger.error(e);
      uploadGuestFailure("File Could not be Processed");
    }
  }, [format, selectedFile, uploadGuestRequest, uploadGuestFailure, uploadGuests]);
  const renderSwitch = (currentStep: number) => {
    switch (currentStep) {
      case FormSteps.ChooseFormat: {
        return <ChooseFormat formatSelected={formatSelected} />;
      }
      case FormSteps.UploadTemplate: {
        return <UploadTemplate format={format} onFileSelected={onFileSelected} onResetFileSelection={onResetFileSelection} />;
      }
      case FormSteps.CompleteUpload: {
        return <CompleteUpload handleFileUpload={handleFileUpload} />;
      }
    }
  };
  const handleNext = () => {
    if (currentStep === FormSteps.CompleteUpload) {
      onUploadModalToggled();
    }
    setCurrentStep((prevIndex) => {
      return getNextStep(prevIndex);
    });
  };
  const nextButtonText = currentStep === FormSteps.CompleteUpload ? "Done" : "Next";
  const nextButtonDisabled = !format || uploadingGuests || (!selectedFile && currentStep === FormSteps.UploadTemplate);
  return (
    <AddGuestsFormContainer>
      <FormProgress
        numberOfSteps={Object.keys(FormSteps).length / 2}
        size={25}
        labels={["Choose format", "Upload Guests", "Complete"]}
        numberOfCompleteSteps={currentStep}
      ></FormProgress>
      {renderSwitch(currentStep)}
      <StyledButtonBottomFlex>
        <AddGuestsFormButtonContainer>
          {currentStep > 0 && (
            <Button
              buttonStyles={{
                ...buttonStyles,
                backgroundColor: Theme.colors.white,
                border: `1px solid ${Theme.colors.darkBlue}`,
                color: Theme.colors.darkBlue,
              }}
              onPress={() => {
                setCurrentStep((prevIndex) => {
                  return getPreviousStep(prevIndex);
                });
              }}
              text="Back"
            />
          )}
          <Button buttonStyles={buttonStyles} disabled={nextButtonDisabled} onPress={handleNext} isLoading={uploadingGuests} text={nextButtonText} />
        </AddGuestsFormButtonContainer>
      </StyledButtonBottomFlex>
    </AddGuestsFormContainer>
  );
};
