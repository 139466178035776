import { useContext, useEffect } from "react";
import { TableContext } from "../../Context/TableContext";
import { StyledFormStepExplanation } from "../../Styles";
import { Theme } from "../../Theme";
import { logger } from "../../Logger";

interface IProps {
  handleFileUpload(): void;
}

export const CompleteUpload = ({ handleFileUpload }: IProps) => {
  const {
    state: { uploadGuestsError, reasonForRowsNotProcessedAfterUpload, uploadingGuests },
    clearUploadMessage,
  } = useContext(TableContext);
  useEffect(() => {
    logger.log("calling useEffect from CompleteUpload component");
    handleFileUpload();
    return () => {
      clearUploadMessage();
    };
  }, [clearUploadMessage, handleFileUpload]);
  return (
    <>
      {uploadGuestsError && <StyledFormStepExplanation color={Theme.colors.maroon}>{uploadGuestsError}</StyledFormStepExplanation>}
      {!uploadGuestsError && !uploadingGuests && (
        <StyledFormStepExplanation color={Theme.colors.darkBlue}>Upload successful!</StyledFormStepExplanation>
      )}
      {uploadingGuests && <StyledFormStepExplanation color={Theme.colors.darkBlue}>Processing...</StyledFormStepExplanation>}
      {reasonForRowsNotProcessedAfterUpload && reasonForRowsNotProcessedAfterUpload?.length > 0 && (
        <>
          <StyledFormStepExplanation>Please note that some rows were not uploaded because:</StyledFormStepExplanation>
          <ul style={{ listStyle: "circle" }}>
            {reasonForRowsNotProcessedAfterUpload.map(({ row, reason }, index) => {
              const message = `${reason} - e.g. row ${row}`;
              return (
                <li>
                  <StyledFormStepExplanation marginBottom="5px">{message}</StyledFormStepExplanation>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};
